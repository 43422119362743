import { useEffect } from 'react'
import Tracking from 'scripts/tracking'
import formatISO from 'date-fns/formatISO'
import { toDateFormat } from 'lib/dateFormat'

const useEditorialTracking = (data) => {
  useEffect(() => {
    if (!data) {
      return
    }

    Tracking.pushToDataLayer({
      event: 'track-author',
      author: data?.author?.name ?? 'Resident Advisor',
    })

    if (data?.date) {
      Tracking.pushToDataLayer({
        event: 'track-date-published',
        datePublished: formatISO(toDateFormat(data.date), {
          representation: 'date',
        }),
      })
    }
  }, [data])
}

export default useEditorialTracking
