import arrayHasData from 'lib/arrayHasData'
import capitalize from 'lodash/capitalize'
import getFeaturedReviewContent from 'scripts/tracking/helpers/getFeaturedReviewContent'

import useNavVersion from 'hooks/useNavVersion'
import { EDITORIAL_TYPE, FEATURE_TYPE } from './view-editorial-mixpanel-types'
import type {
  ArtistType,
  ViewEditorialMixpanelExchangeType,
  ViewEditorialMixpanelPodcastType,
  ViewEditorialMixpanelFeatureType,
  ViewEditorialMixpanelNewsType,
  ViewEditorialMixpanelReviewType,
  ViewEditorialMixpanelDataInput,
} from './view-editorial-mixpanel-types'

const useViewEditorialMixpanelProperties = (
  data: ViewEditorialMixpanelDataInput
) => {
  const navVersion = useNavVersion()

  if (!data) {
    return {}
  }

  const baseProperties = getBaseProperties(data)

  // eslint-disable-next-line no-underscore-dangle
  switch (data.__typename) {
    case EDITORIAL_TYPE.review: {
      const featuredContent = getFeaturedReviewContent(data)
      const featuredContentProperties =
        getFeaturedContentProperties(featuredContent)
      return {
        data: {
          ...baseProperties,
          ...featuredContentProperties,
          Tech: data.type === FEATURE_TYPE.tech,
          Type: capitalize(data.type),
          Author: data?.author?.name,
          Language: capitalize(data.language),
          'Editorial Content ID': data?.id.toString(),
          Recommended: data?.recommended,
          'Nav Version': navVersion,
        },
      } as ViewEditorialMixpanelReviewType
    }
    case EDITORIAL_TYPE.news: {
      const featuredContentProperties = data?.artist
        ? getFeaturedContentProperties(data?.artist)
        : {}
      return {
        data: {
          ...baseProperties,
          ...featuredContentProperties,
          Tech: data.type === FEATURE_TYPE.tech,
          Type: capitalize(data.type),
          Author: data?.author?.name,
          Language: capitalize(data.language),
          'Editorial Content ID': data.id.toString(),
          Headlined: data?.headline,
          'Nav Version': navVersion,
        },
      } as ViewEditorialMixpanelNewsType
    }
    case EDITORIAL_TYPE.feature: {
      const moreOnArtistIsPresent =
        arrayHasData(data?.artists) && data?.artists.length === 1
      const featuredContentProperties = moreOnArtistIsPresent
        ? getFeaturedContentProperties(data?.artists[0])
        : {}
      const mentionedArtists = arrayHasData(data?.artists, 2)
        ? getMentionedArtists(data?.artists)
        : []
      return {
        data: {
          ...baseProperties,
          ...featuredContentProperties,
          ...mentionedArtists,
          'Series Name': data?.series?.title || '',
          Tech: data.tech,
          Film: data.type === FEATURE_TYPE.film,
          Author: data?.author?.name,
          Language: capitalize(data.locale),
          'Editorial Content ID': data.id.toString(),
          Headlined: data?.featured,
          'Nav Version': navVersion,
        },
      } as ViewEditorialMixpanelFeatureType
    }
    case EDITORIAL_TYPE.podcast: {
      const featuredContentProperties = data?.artist
        ? getFeaturedContentProperties(data?.artist)
        : {}
      return {
        data: {
          ...baseProperties,
          ...featuredContentProperties,
          'Editorial Content ID': data.id.toString(),
          Language: capitalize(data?.translation?.language),
          Archived: data?.archived,
          'Nav Version': navVersion,
        },
      } as ViewEditorialMixpanelPodcastType
    }
    case EDITORIAL_TYPE.exchange: {
      const featuredContentProperties =
        data?.artist && data?.artist?.id !== '0'
          ? getFeaturedContentProperties(data?.artist)
          : {}

      return {
        data: {
          ...baseProperties,
          ...featuredContentProperties,
          'Editorial Content ID': data.id.toString(),
          'Nav Version': navVersion,
        },
      } as ViewEditorialMixpanelExchangeType
    }
    default:
      return {}
  }
}

const getBaseProperties = (data: {
  __typename?: string
  date?: string
  title?: string
}) => {
  return {
    // eslint-disable-next-line no-underscore-dangle
    'Editorial Type': data.__typename || '',
    'Publish Date': data.date || '',
    Title: data.title || '',
  }
}

const getFeaturedContentProperties = (featuredContent: ArtistType) => {
  return {
    'Featured Content Object ID': featuredContent?.id?.toString() || '',
    'Featured Content Object Name': featuredContent?.name || '',
    // eslint-disable-next-line no-underscore-dangle
    'Featured Content Object Type': featuredContent?.__typename || '',
  }
}

const getMentionedArtists = (artists: ArtistType[]) => {
  return {
    'Mentioned Artists': artists.reduce(
      (acc, artist) => [...acc, artist.name],
      []
    ),
  }
}

export default useViewEditorialMixpanelProperties
