import {
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import getConfig from 'next/config'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'

const { publicRuntimeConfig } = getConfig()

const MixpanelEditorialTrackingWrapper = (
  props: MixpanelEditorialTrackingWrapperProps
) => {
  const { children, properties: mixpanelProperties = {} } = props
  const elementRef = useRef(null)

  const thresholds =
    publicRuntimeConfig.EDITORIAL_SCROLL_TRACKING_THRESHOLDS.split(',').map(
      (threshold) => parseInt(threshold, 10)
    )
  const throttleWaitTimeInMs =
    publicRuntimeConfig.EDITORIAL_SCROLL_TRACKING_THROTTLE_WAIT_TIME_IN_MILLISECONDS
  const [lastReported, setLastReported] = useState<number>(0)
  const enableEditorialScrollTracking = useFeatureSwitch(
    featureSwitches.enableEditorialScrollTracking
  )

  const reportAmountScrolled = useCallback(
    (scrollPercentage: number, scrollHeight: number) => {
      setLastReported(scrollPercentage)
      Tracking.trackMixpanel(TRACKING_EVENT.scrolledEditorial, {
        scrollPercentage,
        scrollHeightInPixels: scrollHeight,
        ...mixpanelProperties,
      })
    },
    [mixpanelProperties]
  )

  const trackScrollAmount = useCallback(
    (initialY: number) => {
      // If the element has not been rendered yet
      // then we can't track the amount scrolled
      if (!elementRef.current?.scrollHeight || !enableEditorialScrollTracking) {
        return
      }

      // Get the true height of the element (scroll height)
      const scrollHeight = elementRef.current?.scrollHeight
      // Get the visible height of the element (what the user can see without scrolling initially)
      const visibleHeight = scrollHeight - window.innerHeight

      // Get the amount scrolled
      // Note that the current position is negative
      // So we need to invert it
      let currentY = initialY * -1

      // We need to apply a offset to the currentY
      // This is because the element may not the top of the page
      // So we need to offset the currentY by the elements position
      const elementPositionOffset = elementRef.current?.offsetTop
      // we need to add a center offset to the currentY
      // This is because we don't want to start tracking at the top of the element
      // We want to start tracking at the center of the element
      // So we need to offset the currentY by half the window height
      currentY += Math.abs(elementPositionOffset + window.innerHeight / 2)

      // If the scrollHeight < 0 that means the element is within the windows height
      // So we want to set the amount to 100
      // Otherwise we want to calculate the amount
      let scrollPercentage =
        visibleHeight > 0 ? (currentY / scrollHeight) * 100 : 100

      // This amount can be greater than 100
      // So we need to cap it at 100
      scrollPercentage = scrollPercentage > 100 ? 100 : scrollPercentage

      // We only want to report the amount scrolled once
      // and within a certain set of thresholds
      // also find the closest threshold that is smaller than the amount
      const closestThreshold = thresholds.reduce((prev, curr) => {
        return curr <= scrollPercentage ? curr : prev
      })

      // If the closest threshold is greater than the last reported
      // then we want to report it
      if (closestThreshold > lastReported) {
        reportAmountScrolled(closestThreshold, scrollHeight)
      }
    },
    [
      lastReported,
      reportAmountScrolled,
      thresholds,
      enableEditorialScrollTracking,
    ]
  )

  // We want to report the amount scrolled when the user scrolls
  useScrollPosition(
    ({ currPos }) => {
      trackScrollAmount(currPos.y)
    },
    [lastReported],
    elementRef,
    false,
    throttleWaitTimeInMs
  )

  // We want to report the amount scrolled when the component mounts
  // This is the case where the user has not scrolled
  // but the element is in view
  useEffect(() => {
    trackScrollAmount(window.scrollY)
  }, [elementRef, trackScrollAmount, enableEditorialScrollTracking])

  return <div ref={elementRef}>{children}</div>
}

export type MixpanelEditorialTrackingWrapperProps =
  PropsWithChildren<object> & {
    properties?: object
  }

export default MixpanelEditorialTrackingWrapper
