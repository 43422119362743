import arrayHasData from 'lib/arrayHasData'

const getFeaturedReviewContent = (data) => {
  if (arrayHasData(data.labels)) {
    return data.labels[0]
  }
  if (arrayHasData(data.artists)) {
    return data.artists[0]
  }
  return {}
}

export default getFeaturedReviewContent
