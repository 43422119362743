const EDITORIAL_TYPE = {
  feature: 'Feature',
  news: 'News',
  review: 'Review',
  podcast: 'Podcast',
  exchange: 'Exchange',
}

const FEATURE_TYPE = {
  tech: 'TECH',
  film: 'FILM',
}

type ArtistType = {
  id?: string
  name: string
  __typename: string
}

type ViewEditorialMixpanelDataInput = {
  __typename: string
  title: string
  date: string
  id?: number
  type?: typeof FEATURE_TYPE[keyof typeof FEATURE_TYPE]
  author?: {
    name: string
    id: string
  }
  language?: string
  recommended?: boolean
  headline?: boolean
  artist?: ArtistType
  artists?: ArtistType[]
  series?: {
    title: string
  }
  tech?: boolean
  locale?: string
  featured?: boolean
  translation?: {
    language?: string
  }
  archived?: boolean
}

type ViewEditorialMixpanelReviewType = {
  data: {
    'Editorial Type'?: string
    'Publish Date'?: string
    Title?: string
    'Featured Content Object ID'?: string
    'Featured Content Object Name'?: string
    'Featured Content Object Type'?: string
    'Editorial Content ID': string
    Tech: boolean
    Type: string
    Author: string
    Language: string
    Recommended: boolean
    'Nav Version': string
  }
}

type ViewEditorialMixpanelNewsType = {
  data: {
    'Editorial Type'?: string
    'Publish Date'?: string
    Title?: string
    'Featured Content Object ID'?: string
    'Featured Content Object Name'?: string
    'Featured Content Object Type'?: string
    'Editorial Content ID': string
    Tech: boolean
    Type: string
    Author: string
    Language: string
    Headlined: boolean
    'Nav Version': string
  }
}

type ViewEditorialMixpanelFeatureType = {
  data: {
    'Editorial Type'?: string
    'Publish Date'?: string
    Title?: string
    'Featured Content Object ID'?: string
    'Featured Content Object Name'?: string
    'Featured Content Object Type'?: string
    'Editorial Content ID': string
    Language: string
    'Series Name': string
    Tech: boolean
    Film: boolean
    Author: string
    Headlined: boolean
    'Mentioned Artists': ArtistType[]
    'Nav Version': string
  }
}

type ViewEditorialMixpanelPodcastType = {
  data: {
    'Editorial Type'?: string
    'Publish Date'?: string
    Title?: string
    'Featured Content Object ID'?: string
    'Featured Content Object Name'?: string
    'Featured Content Object Type'?: string
    'Editorial Content ID': string
    Language: string
    Archived: boolean
    'Nav Version': string
  }
}

type ViewEditorialMixpanelExchangeType = {
  data: {
    'Editorial Type'?: string
    'Publish Date'?: string
    Title?: string
    'Featured Content Object ID'?: string
    'Featured Content Object Name'?: string
    'Featured Content Object Type'?: string
    'Editorial Content ID': string
    'Nav Version': string
  }
}

export { EDITORIAL_TYPE, FEATURE_TYPE }
export type {
  ArtistType,
  ViewEditorialMixpanelExchangeType,
  ViewEditorialMixpanelPodcastType,
  ViewEditorialMixpanelFeatureType,
  ViewEditorialMixpanelNewsType,
  ViewEditorialMixpanelReviewType,
  ViewEditorialMixpanelDataInput,
}
